<template>
	<el-dialog :title="'查看监理会议'" :close-on-click-modal="false" :visible.sync="visible" width="960px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px">
			<el-row>
				<el-col :span="8">
					<el-form-item label="类型">
						<el-select class="selItemInput1" v-model="dataForm.meetKind" placeholder="请选择" :disabled="true">
							<el-option v-for="item in meetingTypeList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="会议时间">
						<el-date-picker class="selItemInput1" v-model="dataForm.meetTime" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="true">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="发起人">
						<el-input class="selItemInput1" type="input" v-model="dataForm.sponsor" placeholder="请填写"
							:disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="主持人">
						<el-input class="selItemInput1" type="input" v-model="dataForm.hoster" placeholder="请填写"
							:disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="地点">
						<el-input class="selItemInput1" type="input" v-model="dataForm.meetPlace" placeholder="请填写"
							:disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="会议主题">
						<el-input class="selItemInput1" type="input" v-model="dataForm.meetTitle" placeholder="请填写"
							:disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="参会人员">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="2" v-model="dataForm.particapants"
							placeholder="请填写" :disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="会议内容">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="2" v-model="dataForm.meetContent"
							placeholder="请填写" :disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="会议描述用于周报">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.meetDescribe"
							placeholder="请填写会议描述（用于周报）" :disabled="true"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="会议纪要">
						<div class="addFileBox">
							<div v-if="dataForm.meetMinutes">
								<el-button v-preventReClick type="success" size="small"
									@click="selectFile(dataForm.meetMinutesUri)">查看</el-button>
							</div>
							<div v-if="!dataForm.meetMinutes">
								<span>暂无会议纪要扫描文件</span>
							</div>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="签到单">
						<div class="addFileBox">
							<div v-if="dataForm.meetTimesheet">
								<el-button v-preventReClick type="success" size="small"
									@click="selectFile(dataForm.meetTimesheetUri)">查看</el-button>
							</div>
							<div v-if="!dataForm.meetTimesheet">
								<span>暂无会议签到单扫描文件</span>
							</div>
						</div>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row v-for="(item, index) in dataForm.photoRecords" v-if="index % 2 == 0">
				<el-col :span="12">
					<el-card :body-style="{ padding: '0px' }" style="margin-bottom: 10px;margin-right: 5px;height: 100%;">
						<el-image :src="item.photoFilePathUrl" fit="scale-down" class="image"></el-image>
						<div style="padding: 14px;">
							<div style="height: 76px">
								<span>{{ item.photoContent }}</span>
							</div>
						</div>
					</el-card>
				</el-col>
				<el-col :span="12" v-if="dataForm.photoRecords[index + 1]">
					<el-card :body-style="{ padding: '0px' }" style="margin-bottom: 10px;margin-right: 5px;height: 100%;">
						<el-image :src="dataForm.photoRecords[index + 1].photoFilePathUrl" fit="scale-down"
							class="image"></el-image>
						<div style="padding: 14px;">
							<div style="height: 76px">
								<span>{{ dataForm.photoRecords[index + 1].photoContent }}</span>
							</div>
						</div>
					</el-card>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">关闭</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "meetingList-look",
	data() {
		return {
			addImg: addImg,
			id: "",
			visible: true,
			form: {},
			action: "",
			meetingTypeList: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				meetId: "",
				meetTime: "",
				sponsor: "",
				meetKind: "",
				hoster: "",
				meetPlace: "",
				particapants: "",
				meetTitle: "",
				meetContent: "",
				meetMinutes: "",
				meetMinutesName: "",
				meetTimesheet: "",
				meetTimesheetName: ""
			},
			dataRule: {

			},
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		selectFile(url) {
			window.open(url);
		},
		init(id) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				meetId: "",
				meetTime: "",
				sponsor: "",
				meetKind: "",
				hoster: "",
				meetPlace: "",
				particapants: "",
				meetTitle: "",
				meetContent: "",
				meetMinutes: "",
				meetMinutesName: "",
				meetTimesheet: "",
				meetTimesheetName: ""
			};
			this.dataForm.meetId = id ? id : 0;
			this.getMeetingType();
			this.getDetail();
			this.$nextTick(() => {
				this.visible = true;
			})
		},
		getMeetingType() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMeetingType",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.meetingTypeList = data.body;
				}
			});
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/meetinfo/info/" + this.dataForm.meetId,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body
				}
			});
		},
		uploadFileMinutes(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadFileMinutes.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/11/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.meetMinutes = res.body.fileSavePath;
					this.dataForm.meetMinutesName = res.body.fileName;
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		uploadFileTimesheet(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadFileTimesheet.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/11/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.meetTimesheet = res.body.fileSavePath;
					this.dataForm.meetTimesheetName = res.body.fileName;
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/meetinfo/save";
					if (this.dataForm.meetId && this.dataForm.meetId != 0) {
						_url = "/business/meetinfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput1 {
	width: 200px !important
}

.selItemInput.descAreaLg {
	width: 800px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}
</style>
